/*
 * MedGrocer logo only
 */
import React, { Fragment, useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"

import styles from "./utils/layout.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBars,
  faTimes,
  faQuestion,
  faFileAlt,
  faShieldAlt,
} from "@fortawesome/free-solid-svg-icons"

const Navbar = () => {
  const [isMenuActive, setMenuActive] = useState(false)
  const handleMenuActive = () => setMenuActive(!isMenuActive)

  const data = useStaticQuery(graphql`
    {
      logoLong: file(relativePath: { eq: "logos/medgrocer-long-white.png" }) {
        childImageSharp {
          fixed(height: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      logoCircle: file(
        relativePath: { eq: "logos/medgrocer-square--white.png" }
      ) {
        childImageSharp {
          fixed(height: 30) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const navbarItems = [
    {
      name: "Help Center",
      link: "/help-center",
      icon: faQuestion,
      isHiddenInDesktop: false,
    },
    {
      name: "Terms and Conditions",
      link: "/terms-and-conditions",
      icon: faFileAlt,
      isHiddenInDesktop: true,
    },
    {
      name: "Privacy Policy",
      link: "/privacy-policy",
      icon: faShieldAlt,
      isHiddenInDesktop: true,
    },
  ]

  const logoLong = data.logoLong.childImageSharp.fixed
  const logoCircle = data.logoCircle.childImageSharp.fixed

  return (
    <Fragment>
      <nav className="navbar is-fixed-top is-primary is-flex">
        <div className={classNames("ml-1", styles["navbar__brand"])}>
          <Link to="/">
            <Img fixed={logoLong} className="is-hidden-mobile" />
            <Img fixed={logoCircle} className="is-hidden-tablet" />
          </Link>
          <div className={styles["navbar__burgerContainer"]}>
            <a
              href="/#"
              role="button"
              onClick={handleMenuActive}
              aria-label="menu"
              aria-expanded="false"
              className="icon"
            >
              <FontAwesomeIcon icon={isMenuActive ? faTimes : faBars} />
            </a>
          </div>
        </div>
        <div
          className={classNames("navbar-menu", styles["navbar__menu"], {
            "is-active": isMenuActive,
          })}
        >
          <div className="navbar-end mr-1">
            {navbarItems.map(item => (
              <Link
                to={item.link}
                className={item.class}
                onClick={() => setMenuActive(false)}
              >
                <span
                  className={classNames(
                    "navbar-item p-1",
                    styles["navbar__menuItem"],
                    { "is-hidden-desktop": item.isHiddenInDesktop }
                  )}
                >
                  <FontAwesomeIcon
                    icon={item.icon}
                    className="is-hidden-desktop icon mr-1 has-text-primary"
                  />
                  {item.name}
                </span>
              </Link>
            ))}
          </div>
        </div>
      </nav>
      <div
        role="button"
        tabIndex={0}
        aria-label="Navbar Background"
        onKeyDown={() => setMenuActive(false)}
        className={classNames({
          [styles["navbar__backgroundIsActive"]]: isMenuActive,
        })}
        onClick={() => setMenuActive(false)}
      />
    </Fragment>
  )
}

export default Navbar
